// src/App.tsx

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import HomePage from './components/HomePage';
import Login from './components/Login';
import Register from './components/Register';
import { AuthProvider } from './components/AuthProvider';
import ApiKeys from './components/ApiKeys';
import DashboardLayout from './components/DashboardLayout';
import UsageStatistics from './components/UsageStatistics';
import Overview from './components/Overview';
import APIDocs from './components/ApiDocs';
import Profile from './components/Profile';
import CookieConsentBanner from './components/CookiesConsentBanner';
import TermsOfService from './components/TermsOfService';
import MainLayout from './components/MainLayout';
import Sandbox from './components/Sandbox';
import ProtectedRoute from './components/ProtectedPages';
import ContactPage from './components/ContactPage';
import PrivacyPolicy from './components/PrivacyPolicy';
import ScrollToTop from './components/ScrollToTop';

const App: React.FC = () => {
  return (
    <Router>
      <AuthProvider>
        <ScrollToTop />
        <CookieConsentBanner />
        <Header />
        <Routes>
          <Route path='/' element={<MainLayout />}>
            <Route index element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/api-docs" element={<APIDocs />} />
            <Route path='/sandbox' element={<Sandbox />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/profile" element={<Profile />} />
              <Route path="/dashboard" element={<DashboardLayout />}>
                <Route index element={<Overview />} />
                <Route path="api-keys" element={<ApiKeys />} />
                <Route path="usage" element={<UsageStatistics />} />
              </Route>
            </Route>
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;
