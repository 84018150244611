export const initializeGoogleAnalytics = () => {
    if (window.gtag) {
        console.log("Google Analytics (gtag) is already initialized.");
        return;
    }

    // Load the Google Analytics (gtag) script
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_ANALYTICS_GTAG}`;
    script.async = true;
    script.onload = () => {
        // Initialize Google Analytics (gtag) after the script has loaded
        window.dataLayer = window.dataLayer || [];
        function gtag() { window.dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', `${process.env.REACT_APP_ANALYTICS_GTAG}`);
        console.log("Google Analytics (gtag) initialized.");
    };
    script.onerror = () => {
        console.error("Failed to load Google Analytics (gtag) script.");
    };

    document.head.appendChild(script);
};
