import React, { useState, useEffect, useCallback } from 'react';
import "./Sandbox.css";
import axios from 'axios';
import Spinner from './Spinner';

interface Language {
    code: string;
    name: string;
}

interface Flashcard {
    word: string;
    translation: string;
    grammar_type: string;
}

// Hardcoded list of languages
const LANGUAGES: Language[] = [
    { code: 'jp', name: 'Japanese' },
    { code: 'es', name: 'Spanish' },
    { code: 'fr', name: 'French' },
    { code: 'de', name: 'German' },
    { code: 'ru', name: 'Russian' },
    // Add more languages as needed
];

const Sandbox: React.FC = () => {
    const [apiKey, setApiKey] = useState<string>('');
    const [targetLanguage, setTargetLanguage] = useState<string>('');
    const [grammarType, setGrammarType] = useState<string>('');
    const [flashcards, setFlashcards] = useState<Flashcard[]>([]);
    const [error, setError] = useState<string>('');
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [showTranslation, setShowTranslation] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleSearch = useCallback(async () => {
        if (!apiKey || !targetLanguage) {
            setError('API Key and Target Language are required');
            return;
        }

        setLoading(true);

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_SERVER}/api/flashcards`, {
                headers: {
                    'x-api-key': apiKey,
                },
                params: {
                    language: targetLanguage,
                    grammar_type: grammarType || '',
                    limit: 10
                },
            });
            if (response.data.length > 0) {
                setFlashcards(response.data);
                setCurrentIndex(0);
                setShowTranslation(false);
                setError('');
            } else {
                setError("Could not load flashcards. Try again later")
            }
           
        } catch (err) {
            console.error('Error fetching flashcards:', err);
            setError('Error fetching flashcards. Please check the console for details.');
        } finally {
            setLoading(false);
        }
    }, [apiKey, grammarType, targetLanguage]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                handleSearch();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleSearch]);

    const handleShowTranslation = () => {
        setShowTranslation(true);
    };

    const handleHideTranslation = () => {
        setShowTranslation(false);
    };

    const handleNextFlashcard = () => {
        setShowTranslation(false);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % flashcards.length);
    };

    return (
        <div className="sandbox-container">
            <h1>Flashcards API Sandbox</h1>

            <div className="input-group">
                <label htmlFor="apiKey">API Key:</label>
                <input
                    type="password"
                    id="apiKey"
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                    placeholder="Enter your API key"
                />
            </div>

            <div className="input-group">
                <label htmlFor="targetLanguage">Target Language:</label>
                <select
                    id="targetLanguage"
                    value={targetLanguage}
                    onChange={(e) => setTargetLanguage(e.target.value)}
                >
                    <option value="">Select Language</option>
                    {LANGUAGES.map((lang) => (
                        <option key={lang.code} value={lang.name}>{lang.name}</option>
                    ))}
                </select>
            </div>

            <div className="input-group">
                <label htmlFor="grammarType">Grammar Type (Optional):</label>
                <input
                    type="text"
                    id="grammarType"
                    value={grammarType}
                    onChange={(e) => setGrammarType(e.target.value)}
                    placeholder="e.g., noun, verb"
                />
            </div>

            <button onClick={handleSearch} disabled={loading}>
                {loading ? 'Loading...' : 'Search'}
            </button>

            {error && <p className="error">{error}</p>}

            {loading ? (
                <Spinner />
            ) : (
                flashcards.length > 0 && (
                    <div className="flashcard-container">
                        <div className="flashcard">
                            <p><strong>Word:</strong> {flashcards[currentIndex].word}</p>
                            {showTranslation && <p><strong>Translation:</strong> {flashcards[currentIndex].translation}</p>}
                            {showTranslation && <p><strong>Grammar Name:</strong> {flashcards[currentIndex].grammar_type}</p>}
                        </div>

                        <div className="flashcard-controls">
                            {!showTranslation ? (
                                <button onClick={handleShowTranslation}>Show Translation</button>
                            ) : (
                                <button onClick={handleHideTranslation}>Hide Translation</button>
                            )}
                            <button onClick={handleNextFlashcard}>Next</button>
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default Sandbox;