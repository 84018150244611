import React, { useState, useEffect } from 'react';
import './CookieConsentBanner.css'; // Add your CSS styling for the banner
import { initializeGoogleAnalytics } from "../services/initAnalitics";

const CookieConsentBanner: React.FC = () => {
    const [showBanner, setShowBanner] = useState<boolean>(false);

    useEffect(() => {
        const consent = localStorage.getItem('cookiesAccepted');

        // If consent is already given, initialize Google Analytics
        if (consent === 'true') {
            initializeGoogleAnalytics();
        } else if (!consent) {
            setShowBanner(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookiesAccepted', 'true');
        setShowBanner(false);
        initializeGoogleAnalytics(); // Initialize Google Analytics after consent
    };

    const handleReject = () => {
        localStorage.setItem('cookiesAccepted', 'false');
        setShowBanner(false);
    };

    if (!showBanner) {
        return null; // Hide the banner if cookies are accepted or rejected
    }

    return (
        <div className="cookie-consent-banner">
            <p>This website uses cookies to improve your experience. By using this site, you accept our use of cookies.</p>
            <button onClick={handleAccept}>Accept</button>
            <button onClick={handleReject}>Reject</button>
        </div>
    );
};

export default CookieConsentBanner;
