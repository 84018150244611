import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy: React.FC = () => {
    return (
        <div className="privacy-policy__container">
            <div className="privacy-policy__header">
                <h1 className="privacy-policy__title">Privacy Policy</h1>
                <p className="privacy-policy__effective-date">Effective Date: September 25, 2024</p>
            </div>
            <p className="privacy-policy__intro">
                Your privacy is important to us. This Privacy Policy explains how Flashcards API collects, uses, and protects your personal information when you use our services.
            </p>

            <div className="privacy-policy__section">
                <h2 className="privacy-policy__section-title">1. Information We Collect</h2>
                <p className="privacy-policy__text">
                    We only collect the following information:
                </p>
                <ul className="privacy-policy__list">
                    <li className="privacy-policy__list-item"><strong>Email Address:</strong> Provided when you register or contact us.</li>
                </ul>
            </div>

            <div className="privacy-policy__section">
                <h2 className="privacy-policy__section-title">2. How We Use Your Information</h2>
                <p className="privacy-policy__text">We use your email address for the following purposes:</p>
                <ul className="privacy-policy__list">
                    <li className="privacy-policy__list-item">To create and manage your account</li>
                    <li className="privacy-policy__list-item">To respond to inquiries</li>
                    <li className="privacy-policy__list-item">To send service-related communications</li>
                </ul>
            </div>

            <div className="privacy-policy__section">
                <h2 className="privacy-policy__section-title">3. Data Security</h2>
                <p className="privacy-policy__text">
                    We take reasonable steps to protect your email from unauthorized access or disclosure. However, no internet service is entirely secure.
                </p>
            </div>

            <div className="privacy-policy__section">
                <h2 className="privacy-policy__section-title">4. Third-Party Services</h2>
                <p className="privacy-policy__text">
                    Flashcards API may use third-party services, like analytics providers, which access limited user information. We ensure they comply with data privacy regulations.
                </p>
            </div>

            <div className="privacy-policy__section">
                <h2 className="privacy-policy__section-title">5. Your Rights</h2>
                <p className="privacy-policy__text">
                    You have the right to request deletion of your email. Contact us at <a className="privacy-policy__link" href="mailto:contact@flashcardsapi.com">contact@flashcardsapi.com</a>.
                </p>
            </div>

            <div className="privacy-policy__section">
                <h2 className="privacy-policy__section-title">6. Changes to this Privacy Policy</h2>
                <p className="privacy-policy__text">
                    We may update this policy. Changes will be posted on this page with an updated effective date.
                </p>
            </div>

            <div className="privacy-policy__section">
                <h2 className="privacy-policy__section-title">7. Contact Us</h2>
                <p className="privacy-policy__text">
                    For questions, contact us at <a className="privacy-policy__link" href="mailto:contact@flashcardsapi.com">contact@flashcardsapi.com</a>.
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
