import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import './Header.css';  // Optional for adding custom styles

const Header: React.FC = () => {
  const { logout, isAuthenticated, username } = useAuth();

  return (
    <header className="header">
      <nav>
        <ul className="nav-list">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          {isAuthenticated ? (
            <>
              <li>
                <Link to="/sandbox">Sandbox</Link>
              </li>
              <li>
                <Link to="/api-docs">API Documentation</Link>
              </li>
              <li>
                <Link to="/profile">Profile</Link>
              </li>
              <li>
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <span className="welcome-message">Welcome, {username}</span>
              <li>
                <button onClick={logout} className="logout-btn">Logout</button>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link to="/login">Login</Link>
              </li>
              <li>
                <Link to="/register">Register</Link>
              </li>
            </>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
