import React, { useState } from 'react';
import axios from 'axios';
import Spinner from './Spinner';  // Assuming you have a Spinner component
import "./ContactPage.css";

const ContactPage: React.FC = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setSubmitted(false);
        setError('');
        setLoading(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_SERVER}/contact`, {
                name,
                email,
                message,
            }, { withCredentials: true });

            if (response.status === 200) {
                setSubmitted(true);
            } else {
                setError('There was a problem sending your message.');
            }
        } catch (err) {
            setError('There was an error sending your message.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="contact-page__container">
            <h1 className="contact-page__title">Contact Us</h1>
            {submitted ? (
                <div className="contact-page__success-message">
                    Thank you for your message! We'll get back to you shortly.
                </div>
            ) : (
                <form className="contact-page__form" onSubmit={handleSubmit}>
                    <div className="contact-page__form-group">
                        <input
                            placeholder='Name'
                            className="contact-page__input"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="contact-page__form-group">
                        <input
                            placeholder='Email'
                            className="contact-page__input"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="contact-page__form-group">
                        <textarea
                            placeholder='Message'
                            className="contact-page__textarea"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        />
                    </div>
                    <button className="contact-page__submit-button" type="submit" disabled={loading}>
                        {loading ? <Spinner /> : 'Send Message'}
                    </button>
                    {error && <p className="contact-page__error-message">{error}</p>}
                </form>
            )}
        </div>
    );
};

export default ContactPage;


// 00af0336be6202864918276bb8110f9d-1b5736a5-a553f533